// Import package
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { getDashboardTrainingRegistMonthlyGraph } from "../../../appRedux/actions";
import FilterBarChartMonthlyRegister from "../../../components/adminRole/dashboard/FilterBarChartMonthlyRegister";
import DashboardBarChart from "../../../components/adminRole/dashboard/DashboardBarChart";
// Component
export default function BarChartMonthlyRegister({ trainingOptions, locations }) {
  const dispatch = useDispatch();
  const { trainingRegisMonthlyGraph, trainingRegistMonthlyGraphFilter } =
    useSelector(({ dashboard }) => dashboard);
  useEffect(() => {
    dispatch(getDashboardTrainingRegistMonthlyGraph(filter));
  }, []);
  const [filter, setFilter] = useState({
    training_option_id: null,
    status: null,
    start_date: moment().startOf('year').toISOString(),
    end_date: moment().endOf('year').toISOString(),
    location: null,
    category_id: null,
  })

  const handleChangeBarRegisterPerTraining = (training_option_id) => {
    dispatch(getDashboardTrainingRegistMonthlyGraph({ ...trainingRegistMonthlyGraphFilter, training_option_id }));
  };

  const handleChangeBarRegisterPerStatus = (status) => {
    dispatch(getDashboardTrainingRegistMonthlyGraph({ ...trainingRegistMonthlyGraphFilter, status }))
  }

  const getRegistrationMonthlyData = () => {
    let list = []
    trainingRegisMonthlyGraph.map((data) => {
      list.push({
        name: moment(data.month_year).format("MMMM YYYY"),
        pendaftar: data.total_training_registrations,
      })
    })
    return list
  }

  function handleChangeFilter(filter) {
    setFilter(filter)
    dispatch(
      getDashboardTrainingRegistMonthlyGraph(filter)
    );
  }

  return (
    <>
      <FilterBarChartMonthlyRegister
        valueFilterTraining={trainingRegistMonthlyGraphFilter.training_option_id}
        onChangeFilterTraining={handleChangeBarRegisterPerTraining}
        trainingOptions={trainingOptions}
        valueFilterStatus={trainingRegistMonthlyGraphFilter.status}
        onChangeFilterStatus={handleChangeBarRegisterPerStatus}
        locations={locations}
        filter={filter}
        setFilter={handleChangeFilter}
      />
      <DashboardBarChart
        barKey="pendaftar"
        fillColor="#003366"
        data={getRegistrationMonthlyData()}
        barName="Pendaftar"
      />
    </>
  );
}
