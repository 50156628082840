import React, { useEffect, useState } from "react";
import {
  Form,
  Select,
  Input,
  DatePicker,
  Button,
  Modal,
  Descriptions,
  Tag,
  Typography,
  Space,
  Alert,
  Card,
  Checkbox,
} from "antd";
import moment from "moment";
import { useDispatch } from "react-redux";
import { SaveOutlined, UpOutlined, DownOutlined, WhatsAppOutlined } from "@ant-design/icons";
import { toRupiah } from "../../../libs";
import { affiliateChecker, discountChecker } from "../../../appRedux/actions";
import { baseRequest } from "../../../util/Api";
import TextArea from "antd/lib/input/TextArea";
import { getConfiguration } from "../../../appRedux/actions/Configuration";
import {
  CheckCircleOutlined,
} from '@ant-design/icons';

export default function TrainingRegistrationForm({
  trainings = [],
  trainingsLoading = false,
  trainingOptions = [],
  trainingOptionsLoading = false,
  onChangeTrainingsFilter,
  onSubmit,
  trainingRegistration,
}) {
  const [form] = Form.useForm();
  const [locations, setLocations] = useState([]);
  const [inputDiscountCode, setInputDiscountCode] = useState("");
  const [inputAffiliateCode, setInputAffiliateCode] = useState("");
  const [affiliatePercent, setAffiliatePercent] = useState(0);
  const [discountPercent, setDiscountPercent] = useState(null);
  const [discountPrice, setDiscountPrice] = useState(null);
  const [discountId, setDiscountId] = useState(null);
  const [affiliateCode, setAffiliateCode] = useState(null);
  const [isTypeChanged, setIsTypeChanged] = useState(false);
  const [isTrainingOptionIdChanged, setIsTrainingOptionIdChanged] =
    useState(false);
  const [showAdvancedFields, setShowAdvancedFields] = useState(false);
  const [rules, setRules] = useState("")

  useEffect(async () => {
    const data = await baseRequest("get", "/trainings/locations", null);
    let options = [];
    data.map((value) => {
      options.push({
        value: value,
        label: value,
      });
    });
    setLocations(options);
    const config = await getConfiguration("training_rules")
    setRules(config.value)
  }, []);

  useEffect(() => {
    if (trainings.length < 1) {
      form.setFieldsValue({ training_id: null });
    } else {
      if (form.getFieldValue("training_id") !== null) {
        if (inputDiscountCode || inputAffiliateCode) {
          if (isTypeChanged || isTrainingOptionIdChanged) {
            form.setFieldsValue({ training_id: trainings[0].id });
          } else {
            form.setFieldsValue({
              training_id: form.getFieldValue("training_id"),
            });
          }
        } else {
          form.setFieldsValue({ training_id: trainings[0].id });
        }
      } else {
        form.setFieldsValue({ training_id: trainings[0].id });
      }
    }
  }, [trainings]);
  var dispatch = useDispatch();
  return (
    <Form
      scrollToFirstError={true}
      form={form}
      layout="vertical"
      initialValues={{
        training_type: "offline",
        training_location: null,
        training_dates: moment(),
        paid_off: true,
        is_retraining: false,
        dp_deadline: moment(),
      }}
      onValuesChange={(changedValues, allValues) => {
        if (
          "training_option_id" in changedValues ||
          "training_type" in changedValues ||
          "training_location" in changedValues ||
          "training_dates" in changedValues
        ) {
          if (allValues.training_option_id) {
            onChangeTrainingsFilter({
              training_option_id: allValues.training_option_id,
              is_online: allValues.training_type === "online",
              location:
                allValues.training_type === "offline"
                  ? (allValues.training_location ? allValues.training_location : "XXXXXXXXXX")
                  : null,
              start_date: allValues.training_dates
                .startOf("month")
                .toISOString(),
              end_date: allValues.training_dates.endOf("month").toISOString(),
              // status: "available",
            });
          }
          setIsTypeChanged(true);
          setIsTrainingOptionIdChanged(true);
        } else {
          setIsTypeChanged(false);
          setIsTrainingOptionIdChanged(false);
        }
      }}
      onFinish={(v) => {
        const result = {
          training_id: v.training_id,
          discount_id: discountId,
          affiliate_code: affiliateCode,
          affiliate_point:
            v.affiliate_point !== undefined && v.affiliate_point !== ""
              ? v.affiliate_point
              : 0,
          is_online: v.training_type == "online",
          rules_checked: v.rules_checked,
        };
        const training = !trainingRegistration
          ? trainings.find((t) => t.id === result.training_id)
          : trainingRegistration.training;
        const totalPercent =
          discountPercent === 100
            ? discountPercent
            : discountPercent !== null
              ? discountPercent + affiliatePercent
              : affiliatePercent;
        const discount = (totalPercent / 100) * training.price + discountPrice;
        const totalPrice =
          discountPercent !== null ||
            discountPrice !== null ||
            affiliatePercent !== 0
            ? training.price - discount
            : training.price;
        const trainingOption = training.training_option;
        let totalAffiliatePointUsed = result.affiliate_point * 1000;
        if (totalAffiliatePointUsed > totalPrice) {
          totalAffiliatePointUsed = totalPrice;
        }
        Modal.confirm({
          title: "Konfirmasi",
          centered: true,
          width: 600,
          icon: null,
          content: (
            <Descriptions size="small" bordered column={1}>
              <Descriptions.Item label="Training">
                {trainingOption.name + " "}
              </Descriptions.Item>
              <Descriptions.Item label="Tipe Training">
                {result.is_online ? (
                  <Tag color="green" style={{ margin: 0 }}>
                    ONLINE
                  </Tag>
                ) : (
                  <Tag color="blue" style={{ margin: 0 }}>
                    OFFLINE
                  </Tag>
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Jadwal">
                {training.dates_summary}
              </Descriptions.Item>
              {inputDiscountCode !== "" && (
                <Descriptions.Item label="Diskon">
                  {discountPercent === null && discountPrice === null ? (
                    <p style={{ margin: 0 }}>
                      Silahkan klik tombol <b>Terapkan Kode</b> terlebih dahulu
                    </p>
                  ) : discountPercent !== null ? (
                    discountPercent + "%"
                  ) : (
                    toRupiah(discountPrice)
                  )}
                </Descriptions.Item>
              )}
              {inputAffiliateCode !== "" && discountPercent !== 100 && (
                <Descriptions.Item label="Diskon Affiliate">
                  {affiliatePercent === 0 ? (
                    <p style={{ margin: 0 }}>
                      Silahkan klik tombol <b>Terapkan Kode</b> terlebih dahulu
                    </p>
                  ) : (
                    affiliatePercent + "%"
                  )}
                </Descriptions.Item>
              )}
              {result.affiliate_point > 0 && (
                <Descriptions.Item label="Point Affiliasi">
                  {totalAffiliatePointUsed === totalPrice
                    ? totalPrice / 1000
                    : result.affiliate_point}
                </Descriptions.Item>
              )}
              <Descriptions.Item label="Total harga">
                {totalPrice !== training.price && (
                  <Typography.Text delete style={{ display: "block" }}>
                    {toRupiah(training.price)}
                  </Typography.Text>
                )}
                {toRupiah(totalPrice)}
              </Descriptions.Item>
              {result.affiliate_point > 0 && (
                <Descriptions.Item label="Sudah dibayar">
                  {totalAffiliatePointUsed >= 500000 &&
                    totalAffiliatePointUsed < totalPrice ? (
                    <>
                      {toRupiah(totalAffiliatePointUsed)}{" "}
                      <Tag color="success">DP Lunas</Tag>
                    </>
                  ) : totalAffiliatePointUsed === totalPrice ? (
                    <>
                      {toRupiah(totalPrice)} <Tag color="success">Lunas</Tag>
                    </>
                  ) : (
                    toRupiah(totalAffiliatePointUsed)
                  )}
                </Descriptions.Item>
              )}
            </Descriptions>
          ),
          onOk: () => onSubmit(result),
        });
      }}
    >
      {!trainingRegistration && (
        <>
          <Alert message="Jika ada kendala pendaftaran, silahkan hubungi admin melalui whatsapp 081908191001"
            type="info"
            showIcon
            action={
              <a href="https://wa.me/6281908191001?text=Saya%20ada%20kendala%20pendaftaran" target="_blank"
                className="ant-btn ant-btn-sm ant-btn-info"
                style={{ marginTop: "auto", marginBottom: "auto" }}>
                <WhatsAppOutlined /> Whatsapp
              </a>
            }
            closable />
          <Form.Item
            label="Pilihan training"
            name="training_option_id"
            rules={[
              { required: true, message: "Mohon pilih pilihan training" },
            ]}
          >
            <Select
              loading={trainingOptionsLoading}
              showSearch
              placeholder="pilih pilihan training"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {trainingOptions.map((t) => (
                <Select.Option key={t.id} value={t.id} label={t.name}>
                  {t.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Tipe training" name="training_type" required>
            <Select>
              <Select.Option value="offline">Offline</Select.Option>
              <Select.Option value="online">Online</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {
              () => (
                <Form.Item
                  label="Lokasi training"
                  name="training_location"
                  required
                  style={{
                    display:
                      form.getFieldValue("training_type") === "offline"
                        ? "block"
                        : "none",
                  }}
                >
                  <Select options={locations}>
                  </Select>
                </Form.Item>
              )
              // )
            }
          </Form.Item>
          <Form.Item label="Bulan training" name="training_dates" required>
            <DatePicker picker="month" format="MMMM YYYY" allowClear={false} />
          </Form.Item>
        </>
      )}
      {!trainingRegistration ? (
        <Form.Item
          label="Jadwal training"
          rules={[{ required: true, message: "mohon pilih jadwal training" }]}
          name="training_id"
        >
          {trainings.length > 0 ? (
            <Select
              placeholder="pilih jadwal training"
              loading={trainingsLoading}
              disabled={
                form.getFieldValue("training_option_id") === null ||
                form.getFieldValue("training_option_id") === undefined
              }
            >
              {trainings.map((t) => (
                <Select.Option key={t.id} value={t.id} disabled={!t.is_running}>
                  {t.dates_summary}
                </Select.Option>
              ))}
            </Select>
          ) : (
            <>
              {(form.getFieldValue("training_option_id") && ((form.getFieldValue("training_type") == "offline" && form.getFieldValue("training_location")) || form.getFieldValue("training_type") == "online") && form.getFieldValue("training_dates")) ? (
                <Alert message={<span>Kuota sudah penuh atau jadwal belum tersedia di bulan yg anda pilih.<br />Silahkan pilih jadwal bulan berikutnya atau hubungi admin ID-Networkers via whatsapp 0819-0819-1001</span>}
                  type="info"
                  showIcon
                  action={
                    <a href="https://wa.me/6281908191001?text=Saya%20ada%20kendala%20pendaftaran" target="_blank"
                      className="ant-btn ant-btn-sm ant-btn-info"
                      style={{ marginTop: "auto", marginBottom: "auto" }}>
                      <WhatsAppOutlined /> Whatsapp
                    </a>
                  } />
              ) : (
                <Alert message="Silahkan pilih Pilihan training, Tipe training, Lokasi training (jika offline), dan Bulan training terlebih dahulu."
                  type="info" />
              )}
            </>
          )}
        </Form.Item>
      ) : (
        <Form.Item label="Jadwal training">
          <Input
            disabled
            value={
              trainingRegistration.training.training_option.name +
              " - " +
              (!trainingRegistration.is_online
                ? trainingRegistration.training.location
                : "Online") +
              " - " +
              trainingRegistration.training.dates_summary
            }
          />
        </Form.Item>
      )}
      <Form.Item>
        <a
          href={() => false}
          onClick={() => setShowAdvancedFields(!showAdvancedFields)}
        >
          {showAdvancedFields ? <UpOutlined /> : <DownOutlined />} Tampilkan
          lebih {showAdvancedFields ? "sedikit" : "banyak"}
        </a>
      </Form.Item>
      <div style={{ display: showAdvancedFields ? "block" : "none" }}>
        <Form.Item noStyle shouldUpdate>
          {() =>
            !form.getFieldValue("is_retraining") && (
              <>
                <Form.Item
                  name="affiliate_point"
                  label="Gunakan Affiliate Point"
                >
                  <Input type="number" placeholder="Contoh: 200" />
                </Form.Item>
                <Form.Item
                  label="Kode Diskon (Pastikan klik tombol “Terapkan Kode”)"
                  name="discount_id"
                >
                  <Space wrap>
                    <Input.Search
                      placeholder="MASUKKAN KODE"
                      enterButton={"Terapkan Kode"} // <SendOutlined />
                      style={{ width: "300px" }}
                      value={inputDiscountCode}
                      onChange={(e) => setInputDiscountCode(e.target.value)}
                      disabled={
                        form.getFieldValue("training_option_id") === null ||
                        form.getFieldValue("training_option_id") === undefined
                      }
                      onSearch={
                        inputDiscountCode !== ""
                          ? async () => {
                            const result = await dispatch(
                              discountChecker({
                                code: inputDiscountCode,
                                training_id:
                                  form.getFieldValue("training_id"),
                              })
                            );
                            setDiscountPercent(result.percent);
                            setDiscountPrice(result.price);
                            setDiscountId(result.id);
                          }
                          : null
                      }
                    />
                    {discountPercent !== null && (
                      <Tag color="blue" style={{ margin: 0 }}>
                        Diskon {discountPercent}%
                      </Tag>
                    )}
                    {discountPrice !== null && (
                      <Tag color="blue" style={{ margin: 0 }}>
                        Diskon {toRupiah(discountPrice)}
                      </Tag>
                    )}
                  </Space>
                </Form.Item>
                <Form.Item
                  label="Kode Affiliasi (Pastikan klik tombol “Terapkan Kode”)"
                  name="affiliate_code"
                >
                  <Space wrap>
                    <Input.Search
                      placeholder="MASUKKAN KODE"
                      enterButton={"Terapkan Kode"} // <SendOutlined />
                      style={{ width: "300px" }}
                      value={inputAffiliateCode}
                      onChange={(e) => setInputAffiliateCode(e.target.value)}
                      disabled={
                        form.getFieldValue("training_option_id") === null ||
                        form.getFieldValue("training_option_id") ===
                        undefined ||
                        discountPercent === 100
                      }
                      onSearch={
                        inputAffiliateCode !== ""
                          ? async () => {
                            const result = await dispatch(
                              affiliateChecker({
                                code: inputAffiliateCode,
                                training_id:
                                  form.getFieldValue("training_id"),
                              })
                            );
                            setAffiliatePercent(5);
                            setAffiliateCode(result.affiliate_code);
                          }
                          : null
                      }
                    />
                    {affiliatePercent !== 0 && discountPercent !== 100 && (
                      <Tag color="blue" style={{ margin: 0 }}>
                        Diskon {affiliatePercent}%
                      </Tag>
                    )}
                  </Space>
                </Form.Item>
                <Form.Item shouldUpdate noStyle hidden>
                  {() =>
                    form.getFieldValue("already_paid") < 500000 && (
                      <Form.Item
                        label="Batas waktu pelunasan uang muka (Rp 500.000)"
                        name="dp_deadline"
                        rules={[{ required: true, message: "Mohon diisi" }]}
                      >
                        <DatePicker
                          showTime
                          format="dddd, DD MMMM YYYY [pukul] HH:mm"
                          allowClear={false}
                        />
                      </Form.Item>
                    )
                  }
                </Form.Item>
              </>
            )
          }
        </Form.Item>
      </div>

      <Form.Item
        rules={[{ required: true, message: "Mohon untuk menyetujui peraturan training" }]}
        name="rules_checked" valuePropName="checked" label={null}
        onChange={(e) => {
          if (e.target.checked)
            form.setFieldsValue({ rules_checked: "checked" })
          else
            form.setFieldsValue({ rules_checked: null })
        }}
      >
        <Checkbox>Saya telah menyetujui peraturan training <a href="#" onClick={() => {
          Modal.info({
            title: "Peraturan Training",
            centered: true,
            width: 600,
            closable: true,
            icon: <CheckCircleOutlined />,
            content: <div dangerouslySetInnerHTML={{ __html: rules }}></div>,
            onOk: () => { },
          })
        }}>berikut ini</a></Checkbox>
      </Form.Item>

      <center>
        <Button
          htmlType="submit"
          type="primary"
          icon={<SaveOutlined />}
          size="large"
          disabled={!form.getFieldValue('training_id')}
        >
          Daftar
        </Button>
      </center>
    </Form >
  );
}
