// Import package
import { v4 } from "uuid";

// Import file
import {
  SET_TRAINERS_LOADING,
  SET_TRAINER_LOADING,
  SET_TRAINERS,
  SET_TRAINER,
  DELETE_TRAINER,
  UPDATE_TRAINER,
} from "../../constants/ActionTypes";
import { setErrorMessage } from "./Common";
import { baseRequest } from "../../util/Api";
import { setLoadingMessage, setSuccessMessage } from ".";

export const getTrainers = () => (dispatch) => {
  dispatch({
    type: SET_TRAINERS_LOADING,
    payload: true,
  });
  baseRequest("get", "/trainers/")
    .then((data) => {
      dispatch({
        type: SET_TRAINERS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch(setErrorMessage(e));
      dispatch({
        type: SET_TRAINERS_LOADING,
        payload: false,
      });
    });
};

export const getTrainer = (id) => (dispatch) =>
  new Promise((resolve) => {
    dispatch({
      type: SET_TRAINER_LOADING,
      payload: true,
    });
    baseRequest("get", "/trainers/" + id)
      .then((data) => {
        dispatch({
          type: SET_TRAINER,
          payload: data,
        });
        resolve(data);
      })
      .catch((e) => {
        dispatch(setErrorMessage(e));
        dispatch({
          type: SET_TRAINER_LOADING,
          payload: false,
        });
      });
  })

export const updateTrainer = (trainer) => (dispatch) =>
  new Promise((resolve) => {
    const messageKey = v4();
    dispatch(setLoadingMessage("Memperbarui trainer ...", messageKey));
    baseRequest("put", "/trainers/" + trainer.id, null, trainer)
      .then((newData) => {
        dispatch(setSuccessMessage("Memperbarui trainer berhasil", messageKey));
        dispatch({
          type: UPDATE_TRAINER,
          payload: newData,
        });
        resolve(newData);
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, messageKey));
      });
  });

export const createTrainer = (data) => (dispatch) =>
  new Promise((resolve) => {
    const messageKey = v4();
    dispatch(setLoadingMessage("Menambah trainer ...", messageKey));
    baseRequest("post", "/trainers/", null, data)
      .then((newData) => {
        dispatch(setSuccessMessage("Menambah trainer berhasil", messageKey));
        resolve(newData);
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, messageKey));
      });
  });

export const deleteTrainer = (id) => (dispatch) =>
  new Promise((resolve, reject) => {
    const messageKey = v4();
    dispatch(setLoadingMessage("Menghapus trainer ...", messageKey));
    baseRequest("delete", "/trainers/" + id)
      .then(() => {
        dispatch(setSuccessMessage("Menghapus trainer berhasil", messageKey));
        dispatch({
          type: DELETE_TRAINER,
          payload: id,
        });
        resolve();
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, messageKey));
        reject();
      });
  });

export const setTrainersLoading = (loading) => (dispatch) =>
  dispatch({
    type: SET_TRAINERS_LOADING,
    payload: loading,
  });
