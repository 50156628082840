// Import package
import React, { useEffect, useState } from "react";
import { Form, Input, InputNumber, Select, Button } from "antd";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { getConfiguration } from "../../../appRedux/actions/Configuration";

// Component
export default function ParticipantForm({ onSubmit, participant }) {
  const [knowFrom, setKnowFrom] = useState([])
  const configurationName = "know_from_where"

  useEffect(() => {
    getConfiguration(configurationName)
      .then(responseData => {
        setKnowFrom(responseData.value.split("\n"))
      })
  }, [])

  return (
    <Form
      layout="vertical"
      initialValues={
        !participant
          ? {
            shirt_size: "L",
            affiliate_point: 0,
          }
          : {
            ...participant,
          }
      }
      style={{ padding: 10 }}
      onFinish={(values) => {
        onSubmit({ ...values });
      }}
    >
      <Form.Item
        name="name"
        label="Nama lengkap"
        rules={[
          {
            required: true,
            message: "Mohon masukkan nama lengkap peserta!",
          },
        ]}
      >
        <Input placeholder="Dimas Hermawan" />
      </Form.Item>
      <Form.Item
        name="nickname"
        label="Nama Panggilan"
        rules={[
          {
            required: true,
            message: "Mohon masukkan nama panggilan peserta!",
            pattern: "^[a-zA-Z0-9 ]*$"
          },
        ]}
      >
        <Input placeholder="" />
      </Form.Item>
      <Form.Item
        name="email"
        label="Alamat email"
        rules={[
          {
            type: "email",
            required: true,
            message: "Mohon masukkan alamat email dengan benar",
          },
        ]}
      >
        <Input placeholder="email@gmail.com" />
      </Form.Item>
      {!participant && (
        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: "Masukkan password",
            },
          ]}
        >
          <Input placeholder="Masukkan password" />
        </Form.Item>
      )}
      <Form.Item
        name="whatsapp_number"
        label="Nomor whatsapp"
        rules={[
          {
            required: true,
            message: "Mohon masukkan nomor whatsapp peserta!"
          }
        ]}>
        <PhoneInput
          defaultCountry="ID"
          style={{ width: "100%" }}
          placeholder="081362778829"
        />
      </Form.Item>
      <Form.Item name="company" label="Perusahaan">
        <Input placeholder="ID-Networkers (IDN.ID)" />
      </Form.Item>
      <Form.Item name="job_position" label="Jabatan / posisi di perusahaan">
        <Input placeholder="Network Engineer" />
      </Form.Item>
      <Form.Item name="address" label="Alamat tempat tinggal">
        <Input.TextArea placeholder="Jl. Anggrek Rosliana no 12A, RT 3/RW 9, Kemanggisan Slipi Palmerah, Jakarta Barat 11480" />
      </Form.Item>
      <Form.Item
        name="province"
        label="Provinsi"
        rules={[
          {
            required: true,
            message: "Mohon masukkan provinsi tempat tinggal!",
          },
        ]}
      >
        <Select
          placeholder="Pilih provinsi tempat tinggal"
          showSearch
          optionFilterProp="label"
          options={[
            { value: "Aceh", label: "Aceh" },
            { value: "Bali", label: "Bali" },
            { value: "Banten", label: "Banten" },
            { value: "Bengkulu", label: "Bengkulu" },
            { value: "Daerah Istimewa Yogyakarta", label: "Daerah Istimewa Yogyakarta" },
            { value: "Daerah Khusus Ibukota Jakarta", label: "Daerah Khusus Ibukota Jakarta" },
            { value: "Gorontalo", label: "Gorontalo" },
            { value: "Jambi", label: "Jambi" },
            { value: "Jawa Barat", label: "Jawa Barat" },
            { value: "Jawa Tengah", label: "Jawa Tengah" },
            { value: "Jawa Timur", label: "Jawa Timur" },
            { value: "Kalimantan Barat", label: "Kalimantan Barat" },
            { value: "Kalimantan Selatan", label: "Kalimantan Selatan" },
            { value: "Kalimantan Tengah", label: "Kalimantan Tengah" },
            { value: "Kalimantan Timur", label: "Kalimantan Timur" },
            { value: "Kalimantan Utara", label: "Kalimantan Utara" },
            { value: "Kepulauan Bangka Belitung", label: "Kepulauan Bangka Belitung" },
            { value: "Kepulauan Riau", label: "Kepulauan Riau" },
            { value: "Lampung", label: "Lampung" },
            { value: "Maluku", label: "Maluku" },
            { value: "Maluku Utara", label: "Maluku Utara" },
            { value: "Nusa Tenggara Barat", label: "Nusa Tenggara Barat" },
            { value: "Nusa Tenggara Timur", label: "Nusa Tenggara Timur" },
            { value: "Papua", label: "Papua" },
            { value: "Papua Barat", label: "Papua Barat" },
            { value: "Papua Barat Daya", label: "Papua Barat Daya" },
            { value: "Papua Pegunungan", label: "Papua Pegunungan" },
            { value: "Papua Selatan", label: "Papua Selatan" },
            { value: "Papua Tengah", label: "Papua Tengah" },
            { value: "Riau", label: "Riau" },
            { value: "Sulawesi Barat", label: "Sulawesi Barat" },
            { value: "Sulawesi Selatan", label: "Sulawesi Selatan" },
            { value: "Sulawesi Tengah", label: "Sulawesi Tengah" },
            { value: "Sulawesi Tenggara", label: "Sulawesi Tenggara" },
            { value: "Sulawesi Utara", label: "Sulawesi Utara" },
            { value: "Sumatera Barat", label: "Sumatera Barat" },
            { value: "Sumatera Selatan", label: "Sumatera Selatan" },
            { value: "Sumatera Utara", label: "Sumatera Utara" },
          ]} />
      </Form.Item>
      <Form.Item
        name="affiliate_code"
        label="Kode afiliasi"
        tooltip="Kosongkan jika ingin digenerate otomatis"
      >
        <Input placeholder="abhsjw" />
      </Form.Item>
      <Form.Item
        name="affiliate_point"
        label="Poin afiliasi"
        rules={[{ type: "number", min: 0 }]}
      >
        <InputNumber placeholder="100" style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item name="shirt_size" label="Ukuran kaos">
        <Select>
          <Select.Option value={null}>Tanpa kaos</Select.Option>
          <Select.Option value="L">
            L (lebar 50 cm, panjang 69 cm)
          </Select.Option>
          <Select.Option value="XL">
            XL (lebar 52 cm, panjang 71 cm)
          </Select.Option>
          <Select.Option value="XXL">
            XXL (lebar 56 cm, panjang 76 cm)
          </Select.Option>
          <Select.Option value="XXXL">
            XXXL (lebar 67 cm, panjang 77 cm)
          </Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="know_idn_from_where"
        label="Tahu ID-Networkers darimana?"
        rules={[
          {
            required: true,
            message: "Mohon masukkan tahu dari mana!",
          },
        ]}
      >
        <Select placeholder="Pilih">
          {knowFrom.map(item => (
            <Select.Option value={item}>{item}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item>
        <center>
          <Button size="large" type="primary" htmlType="submit">
            Simpan
          </Button>
        </center>
      </Form.Item>
    </Form>
  );
}
