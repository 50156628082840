// Import Package
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// Import File
import { editProfileParticipant, updateParticipant } from "../../appRedux/actions";
import EditProfile from "../../components/participantRole/EditProfile";

// Component
export default function EditProfileParticipantContainer({ userParticipant }) {
  const dispatch = useDispatch();
  const history = useHistory()
  const handleSubmit = (data) => {
    dispatch(editProfileParticipant({ ...data, id: userParticipant.id })).then(() => {
      setTimeout(() => {
        history.push("/my-training/training-registration")
      }, 500)
    })
  };
  return <EditProfile onSubmit={handleSubmit} userParticipant={userParticipant} />;
}
