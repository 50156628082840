import React from "react";
import { Route, Switch } from "react-router-dom";

import asyncComponent from "util/asyncComponent";
import { useSelector } from "react-redux";
import TrainingPage from "./TrainingPage";
import NewTrainingPage from "./NewTrainingPage";
import DetailTrainingPage from "./DetailTrainingPage";
import EditTrainingPage from "./EditTrainingPage";
import RescheduleTrainingPage from "./RescheduleTrainingPage";
import TrainingOptionPage from "./TrainingOptionPage";
import NewTrainingOptionPage from "./NewTrainingOptionPage";
import DetailTrainingOptionPage from "./DetailTrainingOptionPage";
import EditTrainingOptionPage from "./EditTrainingOptionPage";
import TrainingRegistrationPage from "./TrainingRegistrationPage";
import NewTrainingRegistrationPage from "./NewTrainingRegistrationPage";
import EditTrainingRegistrationPage from "./EditTrainingRegistrationPage";
import MutationPage from "./MutationPage";
import DetailTrainingRegistration from "./DetailTrainingRegistration";
import ParticipantPage from "./ParticipantPage";
import NewParticipantPage from "./NewParticipantPage";
import EditParticipantPage from "./EditParticipantPage";
import DetailParticipantPage from "./DetailParticipantPage";
import TrainerPage from "./TrainerPage";
import NewTrainerPage from "./NewTrainerPage";
import EditTrainerPage from "./EditTrainerPage";
import DetailTrainerPage from "./DetailTrainerPage";
import AdminPage from "./AdminPage";
import NewAdminPage from "./NewAdminPage";
import EditAdminPage from "./EditAdminPage";
import TrainingCategoriesPage from "./TrainingCategoriesPage";
import NewTrainingCategoryPage from "./NewTrainingCategoryPage";
import EditTrainingCategoryPage from "./EditTrainingCategoryPage";
import DiscountPage from "./DiscountPage";
import NewDiscountPage from "./NewDiscountPage";
import EditDiscountPage from "./EditDiscountPage";
import TrainingCalendarPage from "./TrainingCalendarPage";
import EditProfilePage from "./EditProfilePage";
import MyTrainingPage from "./MyTrainingPage";
import ParticipantTrainingRegistPage from "./ParticipantTrainingRegistPage";
import DetailDiscountPage from "./DetailDiscountPage";
import DashboardPage from "./DashboardPage";
import AffiliatePage from "./AffiliatePage";
import EditPasswordPage from "./EditPasswordPage";
import AdSlotsPage from "./AdSlotsPage";
import EditAdsPage from "./EditAdsPage";
import NewTrainingImport from "./NewTrainingImport";
import AdminLogs from "./AdminLogs";
import JadwalPage from "./JadwalPage";
import JadwalInformation from "./JadwalPage/JadwalInformation";
import { CounterPage } from "./CounterPage";
import AccountsPage from "./AccountsPage";
import EditAccountsPage from "./EditAccountsPage";
import NewAccountsPage from "./NewAccountsPage";
import KnowFromWhere from "./Configurations/KnowFromWhere";
import InvitationEmail from "./Configurations/InvitationEmail";
import InvitationWhatsapp from "./Configurations/InvitationWhatsapp";
import TrainingRules from "./Configurations/TrainingRules";
import ExportPage from "./ExportPage";

const RoleBasedRoute = ({ component: Component, roles, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  return roles.includes(authUser.role) ? (
    <Route {...rest} render={(props) => <Component {...props} />} />
  ) : (
    "Tidak diizinkan"
  );
};

const App = ({ match }) => (
  <div className="gx-main-content-wrapper" style={{ padding: 15 }}>
    <Switch>
      {/* <Route
        path={`${match.url}verify-registration/:token`}
        component={VerifyRegistration}
        // children={<VerifyRegistration/>}
        // component={asyncComponent(() => import("./VerifyRegistration"))}
      /> */}

      {/* TRAINING */}
      <RoleBasedRoute
        path={`${match.url}training`}
        exact
        roles={["admin", "trainer"]}
        component={TrainingPage}
      />
      <RoleBasedRoute
        path={`${match.url}training/add`}
        exact
        roles={["admin"]}
        component={NewTrainingPage}
      />
      <RoleBasedRoute
        path={`${match.url}training/import`}
        exact
        roles={["admin"]}
        component={NewTrainingImport}
      />
      <RoleBasedRoute
        path={`${match.url}training/:trainingId`}
        exact
        roles={["admin", "trainer"]}
        component={DetailTrainingPage}
      />
      <RoleBasedRoute
        path={`${match.url}training/:trainingId/edit`}
        exact
        roles={["admin"]}
        component={EditTrainingPage}
      />
      <RoleBasedRoute
        path={`${match.url}training/:trainingId/reschedule`}
        exact
        roles={["admin"]}
        component={RescheduleTrainingPage}
      />

      {/* TRAINING OPTION */}
      <RoleBasedRoute
        path={`${match.url}training-option`}
        exact
        roles={["admin"]}
        component={TrainingOptionPage}
      />
      <RoleBasedRoute
        path={`${match.url}training-option/add`}
        exact
        roles={["admin"]}
        component={NewTrainingOptionPage}
      />
      <RoleBasedRoute
        path={`${match.url}training-option/:trainingOptionId/edit`}
        exact
        roles={["admin"]}
        component={EditTrainingOptionPage}
      />
      <RoleBasedRoute
        path={`${match.url}training-option/:trainingOptionId`}
        // exact
        roles={["admin"]}
        component={DetailTrainingOptionPage}
      />

      {/* TRAINING REGISTRATION */}
      <RoleBasedRoute
        path={`${match.url}training-registration`}
        exact
        roles={["admin"]}
        component={TrainingRegistrationPage}
      />
      <RoleBasedRoute
        path={`${match.url}training-registration/add`}
        exact
        roles={["admin"]}
        component={NewTrainingRegistrationPage}
      />
      <RoleBasedRoute
        path={`${match.url}training-registration/:trainingRegistrationId/edit`}
        exact
        roles={["admin"]}
        component={EditTrainingRegistrationPage}
      />
      <RoleBasedRoute
        path={`${match.url}mutation`}
        exact
        roles={["admin"]}
        component={MutationPage}
      />
      <RoleBasedRoute
        path={`${match.url}accounts`}
        exact
        roles={["admin"]}
        component={AccountsPage}
      />
      {/* <RoleBasedRoute
        path={`${match.url}accounts/add`}
        exact
        roles={["admin"]}
        component={NewAccountsPage}
      /> */}
      <RoleBasedRoute
        path={`${match.url}accounts/:bankAccountId/edit`}
        exact
        roles={["admin"]}
        component={EditAccountsPage}
      />
      <RoleBasedRoute
        path={`${match.url}training-registration/:trainingRegistrationId`}
        exact
        roles={["admin", "participant"]}
        component={DetailTrainingRegistration}
      />

      {/* PARTICIPANT */}
      <RoleBasedRoute
        path={`${match.url}participant`}
        exact
        roles={["admin"]}
        component={ParticipantPage}
      />
      <RoleBasedRoute
        path={`${match.url}participant/add`}
        exact
        roles={["admin"]}
        component={NewParticipantPage}
      />
      <RoleBasedRoute
        path={`${match.url}participant/:participantId/edit`}
        exact
        roles={["admin"]}
        component={EditParticipantPage}
      />
      <RoleBasedRoute
        path={`${match.url}participant/:participantId`}
        roles={["admin"]}
        component={DetailParticipantPage}
      />

      {/* TRAINER */}
      <RoleBasedRoute
        path={`${match.url}trainer`}
        exact
        roles={["admin"]}
        component={TrainerPage}
      />

      <RoleBasedRoute
        path={`${match.url}trainer/add`}
        exact
        roles={["admin"]}
        component={NewTrainerPage}
      />

      <RoleBasedRoute
        path={`${match.url}trainer/:trainerId/edit`}
        exact
        roles={["admin"]}
        component={EditTrainerPage}
      />

      <RoleBasedRoute
        path={`${match.url}trainer/:trainerId`}
        exact
        roles={["admin"]}
        component={DetailTrainerPage}
      />

      {/* ADMIN */}
      <RoleBasedRoute
        path={`${match.url}admin`}
        exact
        roles={["admin"]}
        component={AdminPage}
      />
      <RoleBasedRoute
        path={`${match.url}admin/add`}
        exact
        roles={["admin"]}
        component={NewAdminPage}
      />
      <RoleBasedRoute
        path={`${match.url}admin/:adminId/edit`}
        exact
        roles={["admin"]}
        component={EditAdminPage}
      />

      {/* TRAINING CATEGORIES */}
      <RoleBasedRoute
        path={`${match.url}training-category`}
        exact
        roles={["admin"]}
        component={TrainingCategoriesPage}
      />
      <RoleBasedRoute
        path={`${match.url}training-category/add`}
        exact
        roles={["admin"]}
        component={NewTrainingCategoryPage}
      />
      <RoleBasedRoute
        path={`${match.url}training-category/:id/edit`}
        exact
        roles={["admin"]}
        component={EditTrainingCategoryPage}
      />

      {/* DISCOUNT */}
      <RoleBasedRoute
        path={`${match.url}discount`}
        exact
        roles={["admin"]}
        component={DiscountPage}
      />
      <RoleBasedRoute
        path={`${match.url}discount/add`}
        exact
        roles={["admin"]}
        component={NewDiscountPage}
      />
      <RoleBasedRoute
        path={`${match.url}discount/:id/edit`}
        exact
        roles={["admin"]}
        component={EditDiscountPage}
      />
      <RoleBasedRoute
        path={`${match.url}discount/:id`}
        exact
        roles={["admin"]}
        component={DetailDiscountPage}
      />

      {/* Training Calendar */}
      <RoleBasedRoute
        path={`${match.url}calendar`}
        exact
        roles={["admin","trainer"]}
        component={TrainingCalendarPage}
      />

      {/* Profile */}
      <RoleBasedRoute
        path={`${match.url}edit-profile`}
        roles={["admin", "trainer", "participant"]}
        component={EditProfilePage}
      />

      {/* Edit Password */}
      <RoleBasedRoute
        path={`${match.url}edit-password`}
        roles={["admin", "trainer", "participant"]}
        component={EditPasswordPage}
      />

      {/* My Training */}
      <RoleBasedRoute
        path={`${match.url}my-training`}
        exact
        roles={["participant"]}
        component={MyTrainingPage}
      />
      <RoleBasedRoute
        path={`${match.url}my-training/training-registration`}
        exact
        roles={["participant"]}
        component={ParticipantTrainingRegistPage}
      />
      <RoleBasedRoute
        path={`${match.url}training-registration/:trainingRegistrationId`}
        exact
        roles={["participant"]}
        component={DetailTrainingRegistration}
      />
      <RoleBasedRoute
        path={`${match.url}logs`}
        exact
        roles={["admin"]}
        component={AdminLogs}
      />
      <RoleBasedRoute
        path={`${match.url}configurations`}
        exact
        roles={["admin"]}
        component={JadwalPage}
      />
      <RoleBasedRoute
        path={`${match.url}jadwal/information`}
        exact
        roles={["admin"]}
        component={JadwalInformation}
      />
      <RoleBasedRoute
        path={`${match.url}configurations/know_from_where`}
        exact
        roles={["admin"]}
        component={KnowFromWhere}
      />
      <RoleBasedRoute
        path={`${match.url}configurations/invitations/email`}
        exact
        roles={["admin"]}
        component={InvitationEmail}
      />
      <RoleBasedRoute
        path={`${match.url}configurations/invitations/whatsapp`}
        exact
        roles={["admin"]}
        component={InvitationWhatsapp}
      />
      <RoleBasedRoute
        path={`${match.url}configurations/training_rules`}
        exact
        roles={["admin"]}
        component={TrainingRules}
      />
      <RoleBasedRoute
        path={`${match.url}export`}
        exact
        roles={["admin"]}
        component={ExportPage}
      />

      {/* DASHBOARD */}
      <RoleBasedRoute
        path={`${match.url}dashboard`}
        exact
        roles={["admin","participant","trainer"]}
        component={DashboardPage}
      />

      {/* AFFILIATE */}
      <RoleBasedRoute
        path={`${match.url}affiliate`}
        exact
        roles={["participant"]}
        component={AffiliatePage}
      />

      {/* ADS SLOTS */}
      <RoleBasedRoute
        path={`${match.url}ads`}
        exact
        roles={["admin"]}
        component={AdSlotsPage}
      />
      <RoleBasedRoute
        path={`${match.url}ads/update`}
        exact
        roles={["admin"]}
        component={EditAdsPage}
      />
    </Switch>
  </div>
);

export default App;
