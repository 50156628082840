// Import package
import React from "react";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom";
import Calendar from "react-awesome-calendar";

export default function TrainingCalendar({
  trainingCalendar,
  filter,
  onChange,
}) {
  const history = useHistory();

  const getEvents = () => {
    const events = [];
    trainingCalendar.forEach((tc) => {
      events.push({
        id: tc.training_id,
        color: "#fd3153",
        title: tc.training_name,
        from: tc.date,
        to: tc.date,
      });
    });
    return events;
  };

  const getEventsPerTrainer = () => {
    const events = [];
    trainingCalendar
      .filter((t) => (t.trainer !== null ? console.log(t.trainer.name) : null))
      .forEach((training) => {
        training.dates.forEach((date, i) => {
          events.push({
            id: training.id,
            color: "#fd3153",
            title:
              training.training_option.name.split(" ")[0] +
              " hari ke-" +
              (i + 1),
            from: moment(date.date).utc(moment.utc().format()).local().format(),
            to: moment(date.date).utc(moment.utc().format()).local().format(),
          });
        });
      });
    return events;
  };

  return (
    <Calendar
      events={getEvents()}
      onClickEvent={(id) => history.push("/training/" + id)}
      onChange={onChange}
    />
  );
}
