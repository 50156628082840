// Import package
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

// Import file
import { getTrainings, getTrainingCalendar } from "../../appRedux/actions";
import TrainingCalendar from "../../components/trainerRole/TrainingCalendar";
import PageContentWrapper from "../../components/PageContentWrapper";

// Component
export default function TrainingCalendarContainer() {
  const dispatch = useDispatch();
  // const { trainings } = useSelector(({ training }) => training);
  const { authUser } = useSelector(({ auth }) => auth);
  const { calendar } = useSelector(({ training }) => training);

  useEffect(() => {
    dispatch(
      getTrainingCalendar({
        start_date: moment().startOf("month").toISOString(),
        end_date: moment().endOf("month").toISOString(),
        trainer_id: authUser.trainer.id,
      })
    );
  }, [dispatch]);

  const handleOnChange = (v) => {
    const end_date = moment(new Date(v.year, v.month, v.day))
      .add(1, "M")
      .startOf("month")
    const start_date = moment(new Date(v.year, v.month, v.day)).startOf("month")
    dispatch(
      getTrainingCalendar({
        start_date: start_date.toISOString(),
        end_date: end_date.toISOString(),
        trainer_id: authUser.trainer.id,
      })
    );
  };

  return (
    <PageContentWrapper>
      <TrainingCalendar trainingCalendar={calendar} onChange={handleOnChange} />
      ;
    </PageContentWrapper>
  );
}
