import React from "react";
import { Dropdown, Menu } from "antd";
import {
  CalendarOutlined,
  CloseOutlined,
  DeleteOutlined,
  CheckOutlined,
  DownloadOutlined,
  EditOutlined,
  PrinterOutlined,
  MailOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";

export default function TrainingsListItemActions({
  onDownloadAttendance,
  downloadAttendance,
  downloadCertificates,
  cancelable,
  onCancel,
  onDelete,
  onEnable,
  isRunning,
  onDownloadCertificates,
  onEdit,
  onReschedule,
  onPrintNameCard,
  onPrintTableName,
  onSendInvitationEmail,
  onSendInvitationWhatsapp,
}) {
  return (
    <Dropdown
      overlay={
        <Menu>
          <Menu.Item
            key="absensi"
            icon={<DownloadOutlined />}
            onClick={onDownloadAttendance}
            disabled={!downloadAttendance}
          >
            Unduh absensi
          </Menu.Item>
          <Menu.Item
            key="sertifikat"
            icon={<DownloadOutlined />}
            onClick={onDownloadCertificates}
            disabled={!downloadCertificates}
          >
            Unduh sertifikat
          </Menu.Item>
          <Menu.Item key="edit" icon={<EditOutlined />} onClick={onEdit}>
            Edit
          </Menu.Item>
          <Menu.Item
            key="reschedule"
            icon={<CalendarOutlined />}
            onClick={onReschedule}
          >
            Ganti tanggal
          </Menu.Item>
          <Menu.Item
            key="printNameCard"
            icon={<PrinterOutlined />}
            onClick={onPrintNameCard}
          >
            Cetak Kartu Nama
          </Menu.Item>
          <Menu.Item
            key="printTableName"
            icon={<PrinterOutlined />}
            onClick={onPrintTableName}
          >
            Cetak Nama Meja
          </Menu.Item>
          <Menu.Item
            key="sendInvitationEmail"
            icon={<MailOutlined />}
            onClick={onSendInvitationEmail}
          >
            Kirim Undangan Email
          </Menu.Item>
          <Menu.Item
            key="sendInvitationWhatsapp"
            icon={<WhatsAppOutlined />}
            onClick={onSendInvitationWhatsapp}
          >
            Kirim Undangan Whatsapp
          </Menu.Item>
          {isRunning ? (
            <Menu.Item
              danger
              key="cancel"
              icon={<CloseOutlined />}
              onClick={onCancel}
              disabled={!cancelable}
            >
              Batalkan
            </Menu.Item>
          ) : (
            <Menu.Item
              key="enable"
              icon={<CheckOutlined />}
              onClick={onEnable}
              disabled={!cancelable}
            >
              Aktifkan
            </Menu.Item>
          )}

          <Menu.Item
            key="0"
            icon={<DeleteOutlined />}
            danger
            onClick={onDelete}
          >
            Hapus
          </Menu.Item>
        </Menu>
      }
      placement="bottomRight"
      trigger={["click"]}
    >
      <i className="gx-icon-btn icon icon-ellipse-v" />
    </Dropdown>
  );
}
