// Import Package
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "antd";
import {
  useQueryParams,
  StringParam,
  NumberParam,
  withDefault,
} from "use-query-params";
import { useHistory } from "react-router-dom";

// Import File
import ParticipantsList from "../../../../components/adminRole/participant/ParticipantsList";
import {
  getParticipants,
  getParticipant,
  deleteParticipant,
  updateUser,
} from "../../../../appRedux/actions";
import ParticipantChangePassForm from "../../../../components/adminRole/participant/ParticipantChangePassForm";
import { ExclamationCircleFilled } from "@ant-design/icons";

// Component
export default function ParticipantsListContainer() {
  const [showChangePassModal, setShowChangePassModal] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    participants,
    totalParticipants,
    participantsLoading,
    participant,
  } = useSelector(({ participant }) => participant);
  const [query, setQuery] = useQueryParams({
    current_page: withDefault(NumberParam, 1),
    page_size: withDefault(NumberParam, 7),
    search: StringParam,
  });

  useEffect(() => {
    dispatch(getParticipants(query));
  }, [query]);

  const handleChangePagination = (newPage, newPageSize) => {
    setQuery(
      { ...query, current_page: newPage, page_size: newPageSize },
      "push"
    );
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Konfirmasi",
      content: "Apakah anda yakin ingin peserta ini ?",
      onOk: () =>
        dispatch(deleteParticipant(id)).then(() => {
          if (query.current_page > 1 && participants.length - 1 == 0) {
            setQuery(
              { ...query, current_page: query.current_page - 1 },
              "push"
            );
          } else {
            dispatch(getParticipants(query));
          }
        }),
    });
  };
  const handleEdit = (id) => {
    history.push("/participant/" + id + "/edit");
  };

  const handleChangePass = (id) => {
    dispatch(getParticipant(id));
    setShowChangePassModal(true);
  };

  const handleSavePass = (newPassword) => {
    dispatch(
      updateUser({
        id: participant.user_id,
        email: participant.user.email,
        password: newPassword,
        role: "participant",
      })
    ).then(() => {
      setShowChangePassModal(false);
    });
  };

  const handleChangeToTrainer = (id, name) => {
    Modal.confirm({
      title: "Apakah yakin akan mengubah role " + name + " menjadi trainer?",
      icon: <ExclamationCircleFilled />,
      content: '',
      onOk() {
        dispatch(getParticipant(id)).then((participant) => {
          dispatch(updateUser({
            id: participant.user_id,
            email: "-",
            role: "trainer"
          })).then(() => {
            dispatch(getParticipants(query))
          })
        })
      },
      onCancel() {

      },
    });
  }

  return (
    <>
      <ParticipantChangePassForm
        visible={showChangePassModal}
        onCancel={() => setShowChangePassModal(false)}
        onSave={handleSavePass}
      />
      <ParticipantsList
        loading={participantsLoading}
        participants={participants}
        totalParticipants={totalParticipants}
        onChangePagination={handleChangePagination}
        currentPage={query.current_page}
        pageSize={query.page_size}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onChangePass={handleChangePass}
        onChangeToTrainer={handleChangeToTrainer}
      />
    </>
  );
}
