import { Button, Form, Input, List, Modal, Typography, Space, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import CircularProgress from '../../components/CircularProgress'
import PageContentWrapper from '../../components/PageContentWrapper'
import 'react-quill/dist/quill.snow.css'
import { getConfiguration, updateConfiguration } from '../../appRedux/actions/Configuration'
import { ExclamationCircleFilled, SmileOutlined } from '@ant-design/icons'

export default function KnowFromWhere() {
  const [data, setData] = useState([])

  const [form] = Form.useForm()

  const configurationName = "know_from_where"

  useEffect(() => {
    getConfiguration(configurationName)
      .then(responseData => {
        setData(responseData.value.split("\n"))
      })
  }, [])

  function updateData(data) {
    updateConfiguration({
      name: configurationName,
      value: data.join("\n")
    }).then(responseData => {
      const newData = responseData.value.split("\n")
      newData.sort()
      setData(newData)
    })
  }

  function handleAdd(tag) {
    if (data.indexOf(tag) === -1) {
      data.push(tag)
      setData([...data])
      updateData(data)
    }
  }

  function handleClose(tag) {
    const newData = data.filter(value => { return value != tag })
    setData([...newData])
    updateData(newData)
  }

  const showConfirm = (item) => {
    Modal.confirm({
      title: 'Apakah yakin akan menghapus?',
      icon: <ExclamationCircleFilled />,
      content: '',
      onOk() {
        handleClose(item)
      },
      onCancel() {
        
      },
    });
  };

  return <>
    {data == null ?
      <CircularProgress />
      :
      <PageContentWrapper title="Edit Pilihan Tahu Dari Mana" backLink={"/configurations"}>
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            // know_from_where: data
          }}
          style={{ padding: 10 }}
          onFinish={(values) => {
            // updateConfiguration({
            //   name: configurationName,
            //   value: values.know_from_where
            // })
            handleAdd(values.name)
          }}
          onValuesChange={(changedValues, allValues) => {

          }}
        >
          <Form.Item
            name="name"
            label="Pilihan Baru"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary">
              Tambahkan
            </Button>
          </Form.Item>
          {data.length ? (
            <>
              <List
                header={<div>Daftar Pilihan</div>}
                // footer={<div>Footer</div>}
                bordered
                dataSource={data}
                renderItem={(item) => (
                  <List.Item
                    actions={[<a key="list-loadmore-edit" onClick={() => { showConfirm(item) }}>Hapus</a>]}
                  >
                    {item}
                  </List.Item>
                )}
              />
            </>
          ) : (
            <Typography.Text className="ant-form-text" type="secondary">
              ( <SmileOutlined /> Belum ada pilihan. )
            </Typography.Text>
          )}
        </Form>
      </PageContentWrapper >
    }
  </>
}
