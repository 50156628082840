import { Button, Form, List, Modal, Typography } from 'antd'
import { Link, useHistory } from "react-router-dom"
import React, { useEffect, useState } from 'react'
import CircularProgress from '../../components/CircularProgress'
import PageContentWrapper from '../../components/PageContentWrapper'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { getConfiguration, updateConfiguration } from '../../appRedux/actions/Configuration'

export default function JadwalInformation() {
  const [data, setData] = useState(null)

  const [form] = Form.useForm()

  const configurationName = "jadwal_information"

  useEffect(() => {
    getConfiguration(configurationName)
      .then(data => {
        setData(data.value)
      })
  }, [])

  return <>
    {data == null ?
      <CircularProgress />
      :
      <PageContentWrapper title="Edit Informasi Jadwal" backLink={"/configurations"}>
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            information: data
          }}
          style={{ padding: 10 }}
          onFinish={(values) => {
            updateConfiguration({
              name: configurationName,
              value: values.information
            })
          }}
          onValuesChange={(changedValues, allValues) => {

          }}
        >
          <Form.Item
            // label="Informasi"
            name="information"
            style={{ height: 280 }}
            rules={[{ required: true, message: "Mohon isikan informasi jadwal" }]}
          >
            <ReactQuill theme="snow" value={data} onChange={setData} style={{ height: 240 }} />
          </Form.Item>
          <Form.Item>
            <center>
              <Button type="primary" htmlType="submit">
                Simpan
              </Button>
            </center>
          </Form.Item>
        </Form>
      </PageContentWrapper >
    }
  </>
}
