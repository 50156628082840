import { Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts'
import { getDashboardKnowFromWhereGraph } from '../../../appRedux/actions';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8D0B41', '#D39D55', '#5DB996', '#118B50', '#FF8383', '#FFF574', '#A1D6CB', '#A19AD3'];

// const RADIAN = Math.PI / 180;

export default function PieChartKnowFrom() {
  const [data, setData] = useState([])

  useEffect(() => {
    getDashboardKnowFromWhereGraph({}).then((rows) => {
      setData(rows)
    })
  }, [])

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    // const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    // const x = cx + radius * Math.cos(-midAngle * RADIAN);
    // const y = cy + radius * Math.sin(-midAngle * RADIAN);
    // return (
    //   <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
    //     {`${(percent * 100).toFixed(0)}%`}
    //   </text>
    // );
    return (percent * 100).toFixed(2) + "%" + " | " + data[index].value
  };

  return (
    <>
      <ResponsiveContainer width="100%" height={250}>
        <PieChart width={600} height={600}>
          <Tooltip />
          <Legend />
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            outerRadius={80}
            fill="#8884d8"
            dataKey={"value"}
            nameKey={"name"}
            label={renderCustomizedLabel}
            // label={true}
            labelLine={true}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}>
                {index}
              </Cell>
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <center><h4 style={{ color: COLORS[0] }}>Tahu ID-Networkers Dari Mana</h4></center>
    </>
  )
}
