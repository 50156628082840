// Import package
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {Space, Select} from "antd"

// Import file
import { getTrainers, getTrainingCalendar, getTrainings } from "../../appRedux/actions";
import TrainingCalendar from "../../components/adminRole/TrainingCalendar";
import PageContentWrapper from "../../components/PageContentWrapper";

export default function TrainingCalendarAdminContainer() {
  const dispatch = useDispatch();
  const [getStartDate, setStartDate] = useState(moment().startOf("month").toISOString())
  const [getEndDate, setEndDate] = useState(moment().endOf("month").toISOString())
  const { trainings, calendar, calendarFilter } = useSelector(({ training }) => training);
  const { trainers } = useSelector(({ trainer }) => trainer);
  const [filterCalendarPerTrainer, setFilterCalendarPerTrainer] =
        useState(null);
  useEffect(() => {
    dispatch(
      // getTrainings()
      getTrainingCalendar({
        start_date: moment().startOf("month").toISOString(),
        end_date: moment().endOf("month").toISOString(),
      })
    );
    dispatch(getTrainers())
  }, [dispatch]);

  const handleOnChange = (v) => {
    const endDate = moment(new Date(v.year, v.month, v.day)).add(1, 'M').startOf('month').toISOString()
    const startDate = moment(new Date(v.year, v.month, v.day)).startOf("month").toISOString()
    dispatch(getTrainingCalendar({ start_date:  startDate, end_date: endDate }));
    setStartDate(startDate)
    setEndDate(endDate)
  };

  const handleChangeCalendarPerTrainer = (trainerId) => {
    dispatch(getTrainingCalendar({
      start_date: getStartDate,
      end_date: getEndDate,
      trainer_id: trainerId
    }))
  };

  return (
    <PageContentWrapper extra={<Space wrap>
        <Select
          showSearch
          value={calendarFilter}
          placeholder="Semua trainer"
          dropdownMatchSelectWidth={false}
          style={{ width: 200 }}
          onChange={handleChangeCalendarPerTrainer}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <Select.Option value={null}>Semua trainer</Select.Option>
          {trainers.map((t) => (
            <Select.Option key={t.id} value={t.id} label={t.name}>
              {t.name}
            </Select.Option>
          ))}
        </Select>
      </Space>}>
      <TrainingCalendar trainingCalendar={calendar} onChange={handleOnChange} filter={filterCalendarPerTrainer} />
    </PageContentWrapper>
  );
}
