import React from "react";
import { Space, Button, Dropdown, Menu } from "antd";
import {
  CloseOutlined,
  DeleteOutlined,
  DownloadOutlined,
  CheckOutlined,
  CalendarOutlined,
  PrinterOutlined,
  MailOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

export default function TrainingActions({
  onDelete,
  onDownloadAttendance,
  onEdit,
  onCancel,
  onEnable,
  isRunning = true,
  onDownloadCertificates,
  onReschedule,
  onPrintNameCard,
  onPrintTableName,
  onSendInvitationEmail,
  onSendInvitationWhatsapp,
}) {
  const screens = useBreakpoint();
  return (
    <Space>
      <Button type="primary" style={{ margin: 0 }} onClick={onEdit}>
        Edit
      </Button>
      {!screens.xs && (
        <Button
          icon={<CalendarOutlined />}
          style={{ margin: 0 }}
          onClick={onReschedule}
        >
          Ganti tanggal
        </Button>
      )}
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item
              key="absensi"
              icon={<DownloadOutlined />}
              onClick={onDownloadAttendance}
            >
              Unduh absensi
            </Menu.Item>
            <Menu.Item
              key="sertifikat"
              onClick={onDownloadCertificates}
              icon={<DownloadOutlined />}
            >
              Unduh sertifikat
            </Menu.Item>
            <Menu.Item
              key="printNameCard"
              icon={<PrinterOutlined />}
              onClick={onPrintNameCard}
            >
              Cetak Kartu Nama
            </Menu.Item>
            <Menu.Item
              key="printTableName"
              icon={<PrinterOutlined />}
              onClick={onPrintTableName}
            >
              Cetak Nama Meja
            </Menu.Item>
            <Menu.Item
              key="sendInvitationEmail"
              icon={<MailOutlined />}
              onClick={onSendInvitationEmail}
            >
              Kirim Undangan Email
            </Menu.Item>
            <Menu.Item
              key="sendInvitationWhatsapp"
              icon={<WhatsAppOutlined />}
              onClick={onSendInvitationWhatsapp}
            >
              Kirim Undangan Whatsapp
            </Menu.Item>
            {isRunning ? (
              <Menu.Item
                danger
                key="cancel"
                icon={<CloseOutlined />}
                onClick={onCancel}
              >
                Batalkan
              </Menu.Item>
            ) : (
              <Menu.Item
                key="enable"
                icon={<CheckOutlined />}
                onClick={onEnable}
              >
                Aktifkan
              </Menu.Item>
            )}

            <Menu.Item
              key="0"
              icon={<DeleteOutlined />}
              danger
              onClick={onDelete}
            >
              Hapus
            </Menu.Item>
          </Menu>
        }
        placement="bottomRight"
        trigger={["click"]}
      >
        <i className="gx-icon-btn icon icon-ellipse-v" />
      </Dropdown>
    </Space>
  );
}
