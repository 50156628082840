import { PageHeader, Card, Space, List, Modal, Button, DatePicker } from "antd"
import { createTask, setErrorMessage, setLoadingMessage, setSuccessMessage } from "../../appRedux/actions";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useState } from "react";
const { RangePicker } = DatePicker;

export default function ExportPage() {
  const data = [
    {
      title: "Total Pendaftar Berdasarkan Nama Training",
      description: "",
      path: "export_total_registrants_based_on_training_name",
    },
    {
      title: "Total Pendaftar Berdasarkan Kategori Training",
      description: "",
      path: "export_total_registrants_by_training_category",
    },
    {
      title: "Total Pendaftar Berdasarkan Tanggal Pendaftaran",
      description: "",
      path: "export_total_registrants_based_on_registration_date",
    },
    {
      title: "Total Pendaftar Berdasarkan Bulan Daftar",
      description: "",
      path: "export_total_registrants_based_on_registration_month",
    },
    {
      title: "Jumlah kelas yang diajar oleh trainer IDN",
      description: "",
      path: "export_number_of_classes_taught_by_trainers",
    },
    {
      title: "List Training ID-Networkers",
      description: "",
      path: "export_list_training",
    },
  ];

  const dispatch = useDispatch()
  const messageKey = "ExportPage"
  const [startDate, setStartDate] = useState(moment().startOf('month'))
  const [endDate, setEndDate] = useState(moment().endOf('month'))

  const handleExport = (index) => {
    const task = data[index]
    dispatch(setLoadingMessage("Membuat " + task.title + "...", messageKey));
    dispatch(
      createTask({
        task: task.path,
        args: {
          start_date: startDate.format('YYYY-MM-DD'),
          end_date: endDate.format('YYYY-MM-DD'),
        }
      })
    )
      .then((result) => {
        dispatch(setSuccessMessage("Membuat " + task.title + " berhasil", messageKey));
        window.open(result.url, "_blank")
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, messageKey));
      });
  }

  return <>
    <PageHeader
      ghost={false}
      title="File Export"
    />

    <Card bordered={false} style={{ borderRadius: 0 }}>
      <Space direction="vertical" style={{ width: "100%" }}>
        <RangePicker
          defaultValue={[startDate, endDate]}
          onChange={(values) => {
            if (values) {
              setStartDate(values[0])
              setEndDate(values[1])
            }
          }} />
        <List
          bordered
          dataSource={data}
          renderItem={(item, index) => (
            <List.Item
              actions={[
                <Button onClick={() => {
                  handleExport(index)
                  return false
                }}>
                  Export
                </Button>]}
            >
              <List.Item.Meta
                avatar={<></>}
                title={item.title}
                description={item.description}
              />
            </List.Item>
          )}
        />
      </Space>
    </Card>
  </>
}
