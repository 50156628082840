import { Button, Form, Input, List, Modal, Typography } from 'antd'
import { Link, useHistory } from "react-router-dom"
import React, { useEffect, useState } from 'react'
import CircularProgress from '../../components/CircularProgress'
import PageContentWrapper from '../../components/PageContentWrapper'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { getConfiguration, updateConfiguration } from '../../appRedux/actions/Configuration'
import TextArea from 'antd/lib/input/TextArea'

export default function InvitationEmail() {
  const [subject, setSubject] = useState(null)
  const [body, setBody] = useState(null)

  const [form] = Form.useForm()

  const configurationName = "invitation_email"

  useEffect(() => {
    getConfiguration(configurationName)
      .then(data => {
        try {
          const jsonData = JSON.parse(data.value)
          setSubject(jsonData.subject)
          setBody(jsonData.body)
        } catch (err) {
          setSubject('Undangan Training {training_type} {training_name}')
          setBody('<p>Dear Bapak/Ibu {participant_name},</p><p><br></p><p>Kami mengundang Anda untuk mengikuti Training {training_type} {training_name} yang akan diselenggarakan pada:</p><p>Tanggal : {training_dates}</p><p>Waktu : {training_time_start} s.d. {training_time_end}</p><p>Lokasi : {training_room}</p><p><br></p><p>Kami menyarankan untuk menggunakan kendaraan motor atau transportasi umum karena ketersediaan tempat parkir mobil terbatas. Berikut beberapa catatan penting yang perlu diperhatikan:</p><p>1. Mohon untuk mengkonfirmasi apabila terjadi perubahan jadwal atau jika Anda tidak bisa hadir paling lambat H-3 sebelum training dimulai. Jika tidak ada konfirmasi reschedule setelah H-3 training, maka kursi training akan hangus. Namun demikian, peserta tetap memiliki kesempatan untuk mengulang training yang sama sebanyak 2 kali.</p><p>2. Bagi peserta yang mengulang training, harap membawa modul dan kaos training sebelumnya. Peserta yang mengulang akan dikenakan biaya sebesar 35rb rupiah per hari untuk makan siang dan snack. Namun, apabila Anda tidak memerlukan makan siang dan snack, tidak perlu membayar apapun.</p><p>3. Peserta yang membawa laptop harap untuk membawanya selama training berlangsung dengan spesifikasi RAM minimum 4 Gb.</p><p><br></p><p>Jika Anda memiliki pertanyaan atau informasi lain yang ingin ditanyakan, silakan menghubungi admin melalui Whatsapp <a href="https://wa.me/6281908191001" rel="noopener noreferrer" target="_blank">081908191001</a>.</p><p><br></p><p>Terima kasih.</p><p>Salam,</p><p><br></p><p>Chicilia ID-Networkers</p>')
        }
      })
  }, [])

  return <>
    {body == null ?
      <CircularProgress />
      :
      <PageContentWrapper title="Edit Undangan Email" backLink={"/configurations"}>
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            subject: subject,
            body: body,
          }}
          style={{ padding: 10 }}
          onFinish={(values) => {
            updateConfiguration({
              name: configurationName,
              value: JSON.stringify({
                subject: values.subject,
                body: values.body,
              })
            })
          }}
          onValuesChange={(changedValues, allValues) => {

          }}
        >
          <Form.Item
            label="Subject email"
            name="subject"
            rules={[{ required: true, message: "Mohon isikan subject undangan email" }]}
          >
            <Input placeholder='subject email' />
          </Form.Item>
          <Form.Item
            label="Isi email"
            name="body"
            style={{ height: 300 }}
            rules={[{ required: true, message: "Mohon isikan informasi undangan email" }]}
          >
            {/* <TextArea /> */}
            <ReactQuill theme="snow" value={body} onChange={setBody} style={{ height: 240 }} />
          </Form.Item>
          <Form.Item>
            <center>
              <Button type="primary" htmlType="submit">
                Simpan
              </Button>
            </center>
          </Form.Item>
        </Form>
      </PageContentWrapper >
    }
  </>
}